@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      #E1E2EA
    )
    #EFE2DC
}

.loader {
  position: relative;
  width: 20px;
  height: 20px;
  background: #888;
  border-radius: 50%;
  animation: ellipseAnimation 2s linear infinite;
}

@keyframes ellipseAnimation {
  0% {
    border-radius: 50%;
  }

  12.5% {
    border-radius: 0 50% 50% 50%;
    transform: rotate(45deg);
  }

  25% {
    border-radius: 0 0 50% 50%;
    transform: rotate(90deg);
  }

  37.5% {
    border-radius: 0 0 0 50%;
    transform: rotate(135deg);
  }

  50% {
    border-radius: 0;
    transform: rotate(180deg);
  }

  62.5% {
    border-radius: 50% 0 0 0;
    transform: rotate(225deg);
  }

  75% {
    border-radius: 50% 50% 0 0;
    transform: rotate(270deg);
  }

  87.5% {
    border-radius: 50% 50% 50% 0;
    transform: rotate(315deg);
  }

  100% {
    border-radius: 50%;
    transform: rotate(360deg);
  }
}
